<i18n locale="th" lang="yaml" >
error.under_construction : "อยู่ระหว่างการพัฒนา"
</i18n>
<template>
	<div v-show="false" class="temporary-box">
		<div class="title">
			N<span class="text-error text-bold">0</span>t Ready
		</div>
		<div class="description">
			{{$t('error.under_construction')}}
		</div>
	</div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.temporary-box {
	font-family: @font-family-title;
	text-align : center;
	padding : 24px 36px;
	max-width : 450px;
	background-color : @white;
	border : 1px solid @border-color-base;
	margin : 24px auto;
	.title {
		font-size : 4em;
	}
	.description {
		font-size: 2em;
	}
}
</style>
