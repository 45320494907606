<template>
	<div class="info-content">
		<Temporary />
		<div>
			<ul style="padding-left:24px;">
				<li>
						Company Ticket Statistic
						<ul style="padding-left:24px;">
							<li>Number of reported ticket (This Month) : Donut form with status section</li>
							<li>Number of reported ticket (All-Time) : Donut form with status section</li>
						</ul>
				</li>
				<li>
						All Ticket Statistic
						<ul style="padding-left:24px;">
							<li>Number of ticket (This Month) : Donut form with status section</li>
							<li>Number of ticket (All-Time) : Donut form with status section</li>
							<li>Number of ticket (This Month) : Donut form with company section </li>
							<li>Number of ticket (This Month) : Donut form with problem type section </li>
						</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// TODO Ticket Statistic for Company
import Temporary from "@components/common/Temporary.vue"
export default {
	components : {
		Temporary
	} ,
	props : {
		company : {
			type: Object ,
			default : () => []
		}
	}
}
</script>
<style lang="less" scoped>
.info-content {
	padding : 16px;
}
</style>
